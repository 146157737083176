<!--
 * @Author: 钟澳鹏 1269372093@qq.com
 * @Date: 2023-05-17 12:08:30
 * @LastEditors: 钟澳鹏 1269372093@qq.com
 * @LastEditTime: 2023-09-15 09:44:33
 * @FilePath: \dalian\src\components\TabContent2.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="tab-content2">
    <img src="../assets/banner2.png" class="banner">
    <div class="content">
      <div class="tab-box">
        <div class="active">行业前沿</div>
        <div>政策法规</div>
        <div>产业洞察</div>
        <div>公司动态</div>
      </div>
      <div class="tab-content">
        <div class="tab-content-item">
          <img src="../assets/tab3/1.png">
          <div class="left">
            <div class="title">供应链小知识 | 仓库正向物流-FWD-收货业务系统化操作</div>
            <div class="desc">在供应链仓储业务中，分为正向物流和逆向物流业务。本期为大家介绍正向物流-FWD -收获业务的作业流程。一套标准化的仓库作业流程，有助于实现企业服务质量，提升工作效率，降低运作成本。 正向物流——FWD-收获业务流程一、实物入库签收——做好交接，明确责任划分。根据…</div>
            <div class="desc">发布日期 : 2023-05-15</div>
          </div>
        </div>
        <div class="tab-content-item">
          <img src="../assets/tab3/2.png">
          <div class="left">
            <div class="title">数智赋能 精准服务 | 守金全面助力CCC免办监管</div>
            <div class="desc">导 语4月18日浦东开发开放33周年纪念日当天，市委常委、区委书记朱芝松围绕改革开放和自贸试验区建设主题，开展“大兴调查研究”系列调研，莅临泓明进行走访。此前，在浦东新区市场监管局推动下，市场监管总局在浦东开展创新试点，成为全国首个供应链CCC免办便捷通道…</div>
            <div class="desc" style="margin-top:20px">发布日期 : 2023-04-26</div>
          </div>
        </div>
        <div class="tab-content-item">
          <img src="../assets/tab3/3.png">
          <div class="left">
            <div class="title">智能运输，实现车辆轨迹可视化！</div>
            <div class="desc">货物送达时间无法精确预计？车辆在途情况无法掌握？担心与司机电话沟通影响行车安全？想随时查看订单信息及货物在途情况掌握车辆位置、轨迹，获取车辆行驶状态信息守金可以帮您！高效管理 精准交付【车辆轨迹可视化】即针对运输车辆行驶数据，设计和实现了基于守金eTMS系…</div>
            <div class="desc">发布日期 : 2023-04-21</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabContent3'
}
</script>

<style scoped>
.banner{
  height: 651px;
  width: 100%;
 }
 .content{
  width: 1200px;
  margin: 72px auto 56px auto;
 }

 .tab-box{
  display: flex;
  gap: 50px;
 }
  .tab-box>.active{
    background: #4068C7;
  }
   .tab-box>div{
    flex: 1;
    height: 63px;
    background: #474747;
    border-radius: 10px;
    font-size: 24px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tab-content-item{
    display: flex;
    gap: 26px;
    margin-top: 90px;
  }
  .tab-content-item>img{
    width: 536px;
    height: 227px;
  }
  .tab-content-item>.left>.title{
    font-size: 24px;
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    color: #111111;
    margin-bottom: 15px;
  }
  .tab-content-item>.left>.desc{
    font-size: 20px;
    font-family: Alibaba PuHuiTi;
    font-weight: 300;
    color: #111111;
    line-height: 30px;
    text-align: justify;
  }
</style>
