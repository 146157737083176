<!--
 * @Author: 钟澳鹏 1269372093@qq.com
 * @Date: 2023-05-17 10:38:52
 * @LastEditors: 钟澳鹏 1269372093@qq.com
 * @LastEditTime: 2023-05-17 10:54:18
 * @FilePath: \dalian\src\components\GridTitle.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="grid-title">
    {{text}}
  </div>
</template>

<script>
export default {
  name: 'GridTitle',
  props: {
    text: {
      type: String,
      default:''
    }
  }
}
</script>

<style scoped>
.grid-title{
  position: relative;
  padding: 16px 0;
  font-size: 32px;
  font-family: Alibaba PuHuiTi;
  font-weight: 300;
  color: #666666;
  text-align: center;
  border-bottom: 2px solid #CECECE;
}
.grid-title::after{
  content: '';
  position: absolute;
  width: 86px;
  height: 6px;
  background: #4068C7;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
