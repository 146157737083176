<template>
  <div class="tab-content1">
    <img src="../assets/banner1.png" class="banner">
    <div class="content">
      <GridTitle text="产业布局"></GridTitle>
      <div class="grid-1">
        <div class="grid-1-item-1">
          <img src="../assets/tab1/1-1.png">
        </div>
        <div class="grid-1-item-2">
          <img src="../assets/tab1/1-2.png">
        </div>
        <div class="grid-1-item-2">
          <img src="../assets/tab1/1-3.png">
        </div>
        <div class="grid-1-item-2">
          <img src="../assets/tab1/1-4.png">
        </div>
        <div class="grid-1-item-2">
          <img src="../assets/tab1/1-5.png">
        </div>
      </div>
      <GridTitle text="场景介绍"></GridTitle>
      <div class="grid-2">
        <div class="grid-2-item">
          <img src="../assets/tab1/2-1.png">
          <div style="padding:5px 22px;">
            <div class="title">矿产</div>
            <div class="desc">铜矿，硅矿，铝矿等矿产资源</div>
            <div class="btn-box">
              <img src="../assets/arrow.png">
              <div>查看详情</div>
            </div>
          </div>
        </div>
        <div class="grid-2-item">
          <img src="../assets/tab1/2-2.png">
          <div style="padding:5px 22px;">
            <div class="title">新三农</div>
            <div class="desc">生鲜肉类，鸡蛋，虾皮，鱼苗，白糖等副食品</div>
            <div class="btn-box">
              <img src="../assets/arrow.png">
              <div>查看详情</div>
            </div>
          </div>
        </div>
        <div class="grid-2-item">
          <img src="../assets/tab1/2-3.png">
          <div style="padding:5px 22px;">
            <div class="title">智慧能源</div>
            <div class="desc">成品油，LNG，CNG</div>
            <div class="btn-box">
              <img src="../assets/arrow.png">
              <div>查看详情</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GridTitle from './GridTitle.vue'
export default {
  name: 'TabContent1',
  components:{
    GridTitle
  }
}
</script>

<style scoped>
.tab-content1{
 
}
.banner{
  height: 651px;
  width: 100%;
 }
 .content{
  width: 1200px;
  margin: 72px auto 0 auto;
 }

 .grid-1{
    /* 设置容器布局为grid布局 */
    display: grid;
    grid-gap:25px;
    /* 指定每一列的宽度 每个宽度中间用空格隔开 */
    grid-template-columns: 370px 1fr 1fr;
      /* 指定每一行的宽度 每个宽度中间用空格隔开 */
    /* grid-template-rows:285px; */
    /* 单元格顺序,可以实现避免空格 */
    grid-auto-flow: row dense;
    padding: 67px 0 57px 0;
 }
 .grid-1-item-1{
  /* 实现纵向占两行 */
    grid-row: span 2;
 }
  .grid-1-item-1>img{
    width: 370px;
    height: 600px;
  }
  .grid-1-item-2>img{
    width: 387px;
    height: 285px;
  }

  .grid-2{
    /* 设置容器布局为grid布局 */
    display: grid;
    grid-gap:21px;
    /* 指定每一列的宽度 每个宽度中间用空格隔开 */
    grid-template-columns: 1fr 1fr 1fr;
    padding: 65px 0 53px 0;
  }
  .grid-2-item{
    height: 417px;
    border: 1px solid #CECECE;
    padding: 3px;
    box-sizing: border-box;
  }
  .grid-2-item>img{
    height: 249px;
  }

  .title{
    color: #666666;
    font-size: 30px;
    font-family: Alibaba PuHuiTi;
  }
  .desc{
    color: #666666;
    font-size: 16px;
    font-family: Alibaba PuHuiTi;
    margin: 5px 0 23px 0;
  }
  .btn-box{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 132px;
    height: 36px;
    font-size: 18px;
    color: #4068C7;
    border: 1px solid #4068C7;
    border-radius: 18px;
    box-sizing: border-box;
  }
  .btn-box>img{
    height: 14px;
  }
</style>
